<template>
  <v-container class="mt-4">

    <v-flex>
      <v-text-field
          v-model="origin"
          label="Origin"
          dense
      ></v-text-field>

      <v-text-field
          v-model="destination"
          label="Destination"
          dense
      ></v-text-field>
      <v-btn color="orange darken-1"  @click="prosesRequest">Request</v-btn>
    </v-flex>

    <v-flex class="mt-8">
      <v-text-field
          v-model="awbNumber"
          label="Air Way Bill"
          dense
      ></v-text-field>
      <v-btn color="primary"  @click="prosesRequestAWB">Request AWB</v-btn>
    </v-flex>

    <v-flex>
      <p class="mt-4">
        {{ responseResult }}
      </p>
    </v-flex>
    
  </v-container>
</template>

<script>
import axios from "axios";
import qs from 'querystring'

export default {
  name: "TesExpedisi",
  data() {
    return {
      origin: '',
      destination: '',
      awbNumber: '',
      responseResult: 'aaa',
    }
  },
  methods: {
    prosesRequest: function () {
    },


    prosesRequestAWB: function () {
      this.responseResult = this.awbNumber

      // const axios = require('axios')
      // var querystring = require('querystring');

      // const params = new URLSearchParams()
      // params.append('username', 'SASTRO')
      // params.append('api_key', '990c62026a453918b4616c8e0dea67b4')
      const data = {
        'username': 'SASTRO',
        'api_key' : '990c62026a453918b4616c8e0dea67b4'
      };

      // const config = {
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     'User-Agent' : '(Filled with framework request, Ex: Java-Request)'
      //   }
      // }

      // const config = {
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //   }
      // }

      const url = "http://apiv2.jne.co.id:10101/tracing/api/list/v1/cnote/0223492100178003";
      // axios.post(url, data, config)
      //     .then((result) => {
      //       // Do somthing
      //       console.log(" >> hasilnya >> " + result.data)
      //     })
      //     .catch((err) => {
      //       // Do somthing
      //       console.log(err.response)
      //       console.log(`Oke bos`)
      //
      //     })


      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url,
      };
      axios(options);


    }

  }
}
</script>

<style scoped>

</style>